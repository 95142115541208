import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import { Heading } from '../Heading';
import { Caption } from '.';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "caption"
    }}>{`Caption`}</h1>
    <p>{`The Caption is used as the little intro to a `}<a parentName="p" {...{
        "href": "/components/Heading"
      }}>{`Heading`}</a>{`. It nests itself on top of the Heading to give context.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Caption} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777).
    <Playground __position={1} __code={'<>\n  <Caption>Featured</Caption>\n  <Heading as=\"h3\">The hop of your life</Heading>\n</>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Heading,
      Caption,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <>
    <Caption mdxType="Caption">Featured</Caption>
    <Heading as="h3" mdxType="Heading">The hop of your life</Heading>
  </>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      